import React, { Component } from "react";
import LoginForm from "./loginForm/loginForm";
import LicenseForm from "./licenseForm/licenseForm";
import "./login.scss";
import store from "./../../../store";
import { licenseIsValid } from "./../../../actions/licenseActions";
import { withTranslation } from "react-i18next";
import { LoadIndicator } from "devextreme-react";
import { compose } from "redux";
import { loadLoginTranslation } from "../../../actions/menuCardAction";
import Config from "../../../Config";
import { withReduxConnect } from "../../../helpers/withReduxConnect";

const config = new Config();

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { licenseIsValid: undefined, loading: true, userLoaded: false };
    }

    componentDidMount() {
        this.loadInitialData();
        window.addEventListener("storage", this.handleStorageChange);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.userLoaded && this.state.userLoaded) {
            this.loadLanguage();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("storage", this.handleStorageChange);
    }

    loadInitialData = async () => {
        const { i18n } = this.props;
        let language = this.props.identity && this.props.identity.user ? this.props.identity.user.selectedLanguage : "";
        this.setState({ loading: true });

        if (language) {
            i18n.changeLanguage(language);
        }

        try {
            const [licenseValid, loginTranslation] = await Promise.all([
                store.dispatch(licenseIsValid()),
                store.dispatch(loadLoginTranslation(language)),
            ]);

            this.setState({
                licenseIsValid: licenseValid,
                loading: false,
                userLoaded: !!localStorage.getItem("user"),
            });
        } catch (error) {
            console.error("Error loading translations or license:", error);
            this.setState({ loading: false });
        }
    };

    handleStorageChange = (event) => {
        if (event.key === "user") {
            this.setState({ userLoaded: !!localStorage.getItem("user") });
        }
    };

    loadLanguage = () => {
        const { i18n } = this.props;
        const user = localStorage.getItem("user");
        const language = user ? JSON.parse(user).selectedLanguage : "";

        if (language) {
            i18n.changeLanguage(language);
        }
    };

    render() {
        const { loginTranslations } = this.props.settings;
        return (
            <React.Fragment>
                {this.state.loading && (
                    <div id="themeLoading">
                        <LoadIndicator width={80} height={80} />
                    </div>
                )}
                {!this.state.loading && (
                    <div className="login">
                        {this.state.licenseIsValid && (
                            <div className="loginBox">
                                <h2>{loginTranslations ? loginTranslations.login : "Login"}</h2>
                                <LoginForm />
                            </div>
                        )}
                        {!this.state.licenseIsValid && (
                            <div className="loginBox">
                                <h2>
                                    {loginTranslations
                                        ? loginTranslations.enterLicenseKey
                                        : "Bitte gib einen gültigen Lizenzschlüssel ein"}
                                </h2>
                                <LicenseForm forceUpdate={false} />
                            </div>
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return {
        settings,
    };
}

export default compose(withReduxConnect(mapStateToProps), withTranslation(["dynamicTranslation"]))(Login);
