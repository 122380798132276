import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, Column, Editing, Popup, Form, RequiredRule } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux';
import { getToast } from "../../../../helpers/requestHelpers";
import notify from "devextreme/ui/notify";
import { createRecipePrompt, getAllRecipePrompts, deleteRecipePrompt, updateRecipePrompt, resetRecipePrompt } from '../../../../actions/recipePromptActions';
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material';
import { confirm } from 'devextreme/ui/dialog';

const RecipePrompts = () => {
    const { t } = useTranslation(["dynamicTranslation"]);
    const dispatch = useDispatch();
    const [recipePrompts, setRecipePrompts] = useState([]);

    // Load initial data
    useEffect(() => {
        dispatch(getAllRecipePrompts()).then((data) => {
            setRecipePrompts(data.prompts);
        });
    }, [dispatch]);

    // Event handlers
    const onRowInserted = useCallback((e) => {
        dispatch(createRecipePrompt(e.data)).then(() => {
            dispatch(getAllRecipePrompts()).then((data) => {
                setRecipePrompts(data.prompts);
            });
            notify(getToast(t("recipePrompts.promptAdded", "Prompt erfolgreich angelegt"), "success"));
        });
    }, [dispatch, t]);

    const onRowUpdated = useCallback((e) => {
        dispatch(updateRecipePrompt(e.data)).then(() => {
            dispatch(getAllRecipePrompts()).then((data) => {
                setRecipePrompts(data.prompts);
            });
            notify(getToast(t("recipePrompts.promptUpdated", "Prompt erfolgreich aktualisiert"), "success"));
        });
    }, [dispatch, t]);

    const onRowRemoved = useCallback((e) => {
        dispatch(deleteRecipePrompt(e.data.recipePromptId)).then(() => {
            dispatch(getAllRecipePrompts()).then((data) => {
                setRecipePrompts(data.prompts);
            });
            notify(getToast(t("recipePrompts.promptDeleted", "Prompt erfolgreich gelöscht"), "success"));
        });
    }, [dispatch, t]);

    const onResetPrompt = useCallback((e) => {
        confirm(t("recipePrompts.confirmReset", "Möchten Sie diesen Prompt wirklich zurücksetzen?"), "Bestätigung").then((result) => {
            if (result) {
                dispatch(resetRecipePrompt(e.recipePromptId)).then(() => {
                    dispatch(getAllRecipePrompts()).then((data) => {
                        setRecipePrompts(data.prompts);
                    });
                    notify(getToast(t("recipePrompts.promptReset", "Prompt erfolgreich zurückgesetzt"), "success"));
                });
            }
        });
    }, [dispatch, t]);

    const customizeText = (e) => {
        return new Date(e.value).toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', '');
    };

    return (
        <div style={{ margin: 20 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
                {t("recipePrompts.title", "Rezept-Prompts verwalten")}
            </Typography>
            <DataGrid
                dataSource={recipePrompts}
                showBorders={true}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                keyExpr="recipePromptId"
                onRowInserted={onRowInserted}
                onRowUpdated={onRowUpdated}
                onRowRemoved={onRowRemoved}
            >
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >
                    <Popup title={t("recipePrompts.promptDetails", "Prompt Details")} showTitle={true} width={700} height={525} />
                    <Form>
                        <Item itemType="group" colCount={1} colSpan={2}>
                            <Item dataField="name" />
                            <Item dataField="prompt" editorType="dxTextArea" editorOptions={{ height: 200 }} />
                        </Item>
                    </Form>
                </Editing>

                <Column
                    dataField="name"
                    caption={t("recipePrompts.name", "Name")}
                    width={200}
                >
                    <RequiredRule message={t("recipePrompts.nameRequired", "Name ist erforderlich")} />
                </Column>

                <Column
                    dataField="prompt"
                    caption="Prompt"
                >
                    <RequiredRule message={t("recipePrompts.promptRequired", "Prompt ist erforderlich")} />
                </Column>
                <Column
                    dataField="lastModified"
                    type='date'
                    caption="Letzte Änderung"
                    customizeText={customizeText}
                />

                <Column
                    type="buttons"
                    width={110}
                    buttons={[{
                        hint: t("recipePrompts.edit", "Bearbeiten"),
                        icon: 'edit',
                        name: 'edit'
                    }, {
                        hint: t("recipePrompts.delete", "Löschen"),
                        icon: 'trash',
                        name: 'delete'
                    }, {
                        hint: t("recipePrompts.reset", "Zurücksetzen"),
                        icon: 'refresh',
                        onClick: (e) => onResetPrompt(e.row.data)
                    }]}
                />
            </DataGrid>
        </div>
    );
};

export default RecipePrompts;
