import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchTse(tseId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getTse/${tseId}`, requestOptions).then((response) => handleResponse(response));
    };
}

export function fetchTseClients(tseId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getTseClients/${tseId}`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkApiKeyAndSecret() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/checkFiskalyApiKeyAndSecret`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function createNewTse() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/createTse`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function initializeTse(tseId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ tseId: tseId }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/initializeTse`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function createTseClient() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/createTseClient`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function exportTse(data, tseId) {
    const requestOptions = {
        method: "PUT",
        headers: authHeaderForAzure(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/exportTse/${tseId}`, requestOptions).then((response) => handleResponse(response));
    };
}

export function getAllTseExports(tseId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getAllTseExports/${tseId}`, requestOptions).then((response) => handleResponse(response));
    };
}

export function getTseExportById(exportUUID) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getTseExport/${exportUUID}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    return handleResponse(response);
                }
                return response.blob();
            })
    };
}

export function getAllTse() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/getAllTse`, requestOptions).then((response) => handleResponse(response));
    };
}

export function disableTse(tseId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ tseId: tseId }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.cashregisterModule}/disableTse`, requestOptions).then((response) => handleResponse(response));
    };
}

export function getAllDSFinVkExports() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllDSFinVkExports`, requestOptions).then((response) => handleResponse(response));
    };
}

export function downloadDSFinVkExport(exportId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/downloadDSFinVkExport/${exportId}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    return handleResponse(response);
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `dsfinvk-export-${exportId}.tar`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
    };
}