import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, LoadIndicator, Menu, Popup, ScrollView } from "devextreme-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Config from "../../../Config";
import { logoutUser } from "../../../actions/identityActions";
import { getFGLogoUrl } from "../../../actions/licenseActions";
import { loadLoginTranslation } from "../../../actions/menuCardAction";
import { updateUserLanguage } from "../../../actions/userActions";
import { hasRight, viewCookingProcesses } from "../../../helpers/rights";
import { withReduxConnect } from "../../../helpers/withReduxConnect";
import { withRouter } from "../../../helpers/withRouter";
import store from "../../../store";
import CurrentTimers from "../../core/currentTimers/currentTimers";
import Breadcrumbs from "../content/page/breadcrumbs";
import "./header.scss";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const config = new Config();

const languages = [
    {
        code: "fr",
        name: "Francais",
        country_code: "fr",
        germanName: "Französisch",
        longCode: "fr-FR",
    },
    {
        code: "de",
        name: "Deutsch",
        country_code: "de",
        germanName: "Deutsch",
        longCode: "de-DE",
    },
    {
        code: "en",
        name: "English",
        country_code: "gb",
        germanName: "Englisch",
        longCode: "en-GB",
    },
    {
        code: "ar",
        name: "Arabian",
        country_code: "sa",
        germanName: "Arabisch",
        longCode: "ar-SA",
    },
    {
        code: "ba",
        name: "basque",
        country_code: "es",
        germanName: "Baskisch",
        longCode: "eu-ES",
    },
    {
        code: "bu",
        name: "bulgaria",
        country_code: "bg",
        germanName: "Bulgarisch",
        longCode: "bg-BG",
    },
    {
        code: "ca",
        name: "catalan",
        country_code: "es",
        germanName: "Katalanisch",
        longCode: "ca-ES",
    },
    {
        code: "ch",
        name: "chinese",
        country_code: "cn",
        germanName: "Chinesisch",
        longCode: "zh-CN",
    },
    {
        code: "cr",
        name: "croatian",
        country_code: "hr",
        germanName: "Kroatisch",
        longCode: "hr-HR",
    },
    {
        code: "cz",
        name: "czech",
        country_code: "cz",
        germanName: "Tschechisch",
        longCode: "cs-CZ",
    },
    {
        code: "da",
        name: "danish",
        country_code: "dk",
        germanName: "Dänisch",
        longCode: "da-DK",
    },
    {
        code: "es",
        name: "spanish",
        country_code: "es",
        germanName: "Spanisch",
        longCode: "es-ES",
    },
    {
        code: "fi",
        name: "finnish",
        country_code: "fi",
        germanName: "Finnisch",
        longCode: "fi-FI",
    },
    {
        code: "ga",
        name: "gallician",
        country_code: "es",
        germanName: "Gallisch",
        longCode: "gl-ES",
    },
    {
        code: "gr",
        name: "greek",
        country_code: "gr",
        germanName: "Griechisch",
        longCode: "el-GR",
    },
    {
        code: "hu",
        name: "hungarian",
        country_code: "hu",
        germanName: "Ungarisch",
        longCode: "hu-HU",
    },
    {
        code: "it",
        name: "italian",
        country_code: "it",
        germanName: "Italienisch",
        longCode: "it-IT",
    },
    {
        code: "la",
        name: "latvian",
        country_code: "lv",
        germanName: "Lettisch",
        longCode: "lv-LV",
    },
    {
        code: "li",
        name: "lithuanian",
        country_code: "lt",
        germanName: "Litauisch",
        longCode: "lt-LT",
    },
    {
        code: "nl",
        name: "dutch",
        country_code: "nl",
        germanName: "Niederländisch",
        longCode: "nl-NL",
    },
    {
        code: "po",
        name: "portuguese",
        country_code: "pt",
        germanName: "Portugiesisch",
        longCode: "pt-PT",
    },
    {
        code: "ro",
        name: "romanian",
        country_code: "ro",
        germanName: "Rumänisch",
        longCode: "ro-RO",
    },
    {
        code: "ru",
        name: "russian",
        country_code: "ru",
        germanName: "Russisch",
        longCode: "ru-RU",
    },
    {
        code: "sl",
        name: "slovenian",
        country_code: "sl",
        germanName: "Slowenisch",
        longCode: "sl-SI",
    },
    {
        code: "so",
        name: "slovakian",
        country_code: "sk",
        germanName: "Slowakisch",
        longCode: "sk-SK",
    },
    {
        code: "sw",
        name: "swedish",
        country_code: "se",
        germanName: "Schwedisch",
        longCode: "sv-SE",
    },
    {
        code: "tu",
        name: "turkish",
        country_code: "tr",
        germanName: "Türkisch",
        longCode: "tr-TR",
    },
    {
        code: "uk",
        name: "ukrainian",
        country_code: "ua",
        germanName: "Ukrainisch",
        longCode: "uk-UA",
    },
];

class Header extends Component {
    constructor(props) {
        super(props);
        this.handleResetApp = this.handleResetApp.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            showLanguageSelection: false,
            biosLogoUrl: undefined,
            sortedLanguages: [],
            loading: false,
            language: "de",
        };

        this.toggleLanguageSelection = this.toggleLanguageSelection.bind(this);
        this.sortLanguages = this.sortLanguages.bind(this);
    }

    handleResetApp() {
        store.dispatch({ type: "APP_RESET" });
    }

    handleLogout = () => {
        store.dispatch(logoutUser()).then(() => {
            this.props.router.history.push("/login");
        });
    };

    toggleLanguageSelection() {
        this.setState({ showLanguageSelection: !this.state.showLanguageSelection });
    }

    componentDidMount() {
        store.dispatch(getFGLogoUrl()).then((result) => {
            this.setState({
                biosLogoUrl: result.logoUrl,
            });
        });

        this.sortLanguages(languages);
    }

    sortLanguages(languages) {
        const { selectedLanguage } = this.props.identity.user;
        const { t } = this.props;
        var selected = languages.filter((language) => language.code === selectedLanguage);
        var rest = languages.filter((language) => language.code !== selectedLanguage);
        languages = [
            ...selected,
            ...rest
                .filter((language) => language.code !== selectedLanguage)
                .sort((a, b) => t(a.germanName).localeCompare(t(b.germanName))),
        ];

        this.setState({ sortedLanguages: languages });
    }

    render() {
        const { theme } = this.props;
        const { t, i18n } = this.props;
        const { selectedLanguage } = this.props.identity.user;
        return (
            <React.Fragment>
                {this.state.loading && (
                    <div id="themeLoading">
                        <LoadIndicator width={80} height={80} />
                    </div>
                )}
                {!this.state.isLoading && (
                    <React.Fragment>
                        {" "}
                        <header id="header" className={this.props.identity.user.Token ? "logged-in" : "not-logged-in"}>
                            <div id="logo">
                                {this.state.biosLogoUrl && (
                                    <img
                                        src={config.imageStorageBlobURL + "" + this.state.biosLogoUrl}
                                        alt={theme.title}
                                    />
                                )}
                                <div id="logoText" style={{ fontSize: "small" }}>
                                    {theme.title}
                                </div>
                                <Breadcrumbs />
                            </div>

                            {/* {config.selectedTheme === "Bios" && <div id="logo">BIOS</div>}
                    {config.selectedTheme === "Rewe" && (
                        <div id="logo">
                            <img src={reweLogo} alt="Logo" />
                            <p id="logoText">Guido Hörle oHG</p>
                        </div>
                    )}
                    {config.selectedTheme === "Sandbox" && (
                        <div className="sandbox" id="logo">
                            <img src={sandboxLogo} alt="Logo" />
                            <p id="logoText">Mein BIOS</p>
                        </div>
                    )}
                    {config.selectedTheme === "Sandbox_Tomate" && (
                        <div className="sandbox" id="logo">
                            <img src={sandboxLogo_Tomate} alt="Logo" />
                            <p id="logoText">Red Tomato – Bio Supermarkt</p>
                        </div>
                    )}
                    {config.selectedTheme === "Sandbox_Kronen" && (
                        <div className="sandbox kronen" id="logo">
                            <img src={sandboxLogo_Kronen} alt="Logo" />
                            <p id="logoText">Kronen Küchengeräte GmbH</p>
                        </div>
                    )}
                    {config.selectedTheme === "Sandbox_Brot" && (
                        <div className="sandbox" id="logo">
                            <img src={sandboxLogo_Brot} alt="Logo" />
                            <p id="logoText">Hans Bäcker Catering GmbH</p>
                        </div>
                    )} */}

                            {!this.props.noUserMenu && (
                                <div id="userMenu">
                                    {this.props.identity &&
                                        this.props.identity.user &&
                                        this.props.identity.user.Token &&
                                        hasRight(viewCookingProcesses) && <CurrentTimers />}
                                    <Menu
                                        onItemClick={(e) => {
                                            if (e.itemData.text === "Logout") this.handleLogout();
                                        }}
                                        visible={
                                            this.props.identity &&
                                            this.props.identity.user &&
                                            this.props.identity.user.Username &&
                                            true
                                        }
                                        items={[
                                            {
                                                text: this.props.identity.user.FullName
                                                    ? this.props.identity.user.FullName
                                                    : this.props.identity.user.Username,
                                                icon: "user",
                                                items: [
                                                    {
                                                        text: "Logout",
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </div>
                            )}

                            {!this.props.noUserMenu && (
                                <React.Fragment>
                                    <div id="reloadButton" onClick={this.handleResetApp}>
                                        <FontAwesomeIcon icon="undo" />
                                    </div>
                                    <div
                                        id="languageButton"
                                        onClick={() => {
                                            if (window.location.hash !== "#/auftraege/erfassen")
                                                this.toggleLanguageSelection();
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faGlobe}
                                            color={window.location.hash !== "#/auftraege/erfassen" ? "" : "grey"}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </header>
                        <Popup
                            visible={this.state.showLanguageSelection}
                            onHiding={() => this.toggleLanguageSelection()}
                            onShowing={() => this.sortLanguages(languages)}
                            hideOnOutsideClick={true}
                            width={300}
                            height={700}
                            title={t("SelectLanguage")}
                        >
                            <ScrollView width="100%" height="100%" useNative>
                                <div>
                                    {this.state.sortedLanguages.map(
                                        ({ code, name, country_code, germanName, longCode }, index) => {
                                            return (
                                                <div key={index} style={{ padding: "0px 10px" }}>
                                                    <Button
                                                        onClick={() => {
                                                            if (
                                                                this.props.identity &&
                                                                this.props.identity.user &&
                                                                this.props.identity.user.Token
                                                            ) {
                                                                i18n.changeLanguage(code);
                                                                this.setState({ loading: true });
                                                                store
                                                                    .dispatch(updateUserLanguage(code, longCode))
                                                                    .then(() => {
                                                                        window.location.reload();
                                                                    });
                                                            } else {
                                                                store.dispatch(
                                                                    loadLoginTranslation(
                                                                            code
                                                                    )
                                                                );
                                                            }
                                                            this.toggleLanguageSelection();
                                                        }}
                                                        className={
                                                            "language-button " +
                                                            (code === selectedLanguage ? "selected" : "")
                                                        }
                                                        style={{ marginBottom: "10px", textAlign: "left" }}
                                                        width={"100%"}
                                                    >
                                                        <span
                                                            style={{ marginRight: 15 }}
                                                            className={`fi fi-${country_code}  `}
                                                        ></span>
                                                        {t(germanName) + " - " + name}
                                                    </Button>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </ScrollView>
                        </Popup>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

Header.defaultProps = {
    noUserMenu: false,
};

const mapStateToProps = (state) => ({
    identity: state.identity,
    theme: state.theme,
});

export default compose(withReduxConnect(mapStateToProps), withTranslation(["dynamicTranslation"]), withRouter)(Header);
