import Config from "../Config";
import { authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();


export function getAllRecipePrompts() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllRecipePrompts`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function createRecipePrompt(recipePrompt) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify(recipePrompt)
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/createRecipePrompt`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function deleteRecipePrompt(recipePromptId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ recipePromptId })
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/deleteRecipePrompt`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function updateRecipePrompt(recipePrompt) {
    return function (dispatch) {
        const requestOptions = {
            method: "PUT",
            headers: authHeaderForAzure(),
            body: JSON.stringify(recipePrompt)
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/updateRecipePrompt`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}

export function resetRecipePrompt(recipePromptId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ recipePromptId })
        };
        return fetch(`${config.apiUrl}/${config.coreModule}/resetRecipePrompt`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    }
}