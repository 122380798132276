import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import { withTranslation } from "react-i18next";
import {
    checkApiKeyAndSecret,
    getAllDSFinVkExports,
    downloadDSFinVkExport,
} from "../../../../actions/tseAction";
import { createDSFinVKExport } from "../../../../actions/dailyClosingAction";
import store from "../../../../store";
import { Button, LoadPanel, SelectBox, DataGrid, Popup, DateBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../../helpers/requestHelpers";

const DsfinvkExports = ({ t }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [exports, setExports] = useState([]);
    const [showDsFinVkPopup, setShowDsFinVkPopup] = useState(false);
    const [exportFormat, setExportFormat] = useState('tar');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const loadExports = () => {
        store.dispatch(getAllDSFinVkExports()).then((response) => {
            setExports(response.data || []);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        store.dispatch(checkApiKeyAndSecret()).then((response) => {
            if (response.apiCredentialsAvailable) {
                loadExports();
            }
            setIsLoading(false);
        });
    }, []);

    const handleExport = async () => {
        if (!startDate || !endDate) return;

        const exportData = {
            start_date: Math.floor(startDate.getTime() / 1000),
            end_date: Math.floor(endDate.getTime() / 1000),
            format: exportFormat
        };

        try {
            const response = await store.dispatch(createDSFinVKExport(exportData));

            if (response) {
                if (response.error) {
                    notify(
                        getToast(
                            response.error,
                            "error"
                        )
                    );
                }
                else {
                    notify(
                        getToast(
                            t("dailyClosingOverview.exportSuccess", "DSFinVK-Export wurde erfolgreich gestartet"),
                            "success"
                        )
                    );
                    setShowDsFinVkPopup(false);
                    loadExports(); // Aktualisiere die Liste nach erfolgreichem Export
                }
            } else {
                throw new Error(t("dailyClosingOverview.exportError", "Fehler beim Erstellen des DSFinVK-Exports"));
            }
        } catch (error) {
            notify(
                getToast(
                    error.message || t("dailyClosingOverview.exportError", "Fehler beim Erstellen des DSFinVK-Exports"),
                    "error"
                )
            );
        }
    };

    const handleDownloadExport = (exportId) => {
        try {
            store.dispatch(downloadDSFinVkExport(exportId));
        } catch (error) {
            console.error('Download error:', error);
            notify(
                getToast(
                    t("dailyClosingOverview.downloadError", "Fehler beim Herunterladen des DSFinVK-Exports"),
                    "error"
                )
            );
        }
    };

    const getSortedExports = () => {
        return [...exports].sort((a, b) => b.time_request - a.time_request);
    };

    const hasPendingExport = () => {
        return exports.some(exp => exp.state === 'PENDING' || exp.state === 'WORKING');
    };

    return (
        <div className="tse-container">
            <LoadPanel visible={isLoading} />
            <div className="exports-section">
                <div className="section-header">
                    <h3>Exports</h3>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            text="NEUER EXPORT"
                            type="default"
                            stylingMode="contained"
                            onClick={() => setShowDsFinVkPopup(true)}
                        />
                        <Button
                            text="AKTUALISIEREN"
                            type="default"
                            stylingMode="contained"
                            onClick={loadExports}
                        />
                    </div>
                </div>
                <table className="exports-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Angefordert am</th>
                            <th>Format</th>
                            <th>Fehler</th>
                            <th>Aktion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getSortedExports().map(exp => (
                            <tr key={exp._id}>
                                <td>{exp._id}</td>
                                <td>{exp.state}</td>
                                <td>{new Date(exp.time_request * 1000).toLocaleString()}</td>
                                <td>{exp.format}</td>
                                <td>{exp.error ? exp.error.message : '-'}</td>
                                <td>
                                    <Button
                                        text="HERUNTERLADEN"
                                        type="default"
                                        stylingMode="contained"
                                        className="dx-button-success"
                                        disabled={exp.state !== 'COMPLETED'} // Only enable for finished exports
                                        onClick={() => handleDownloadExport(exp._id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Popup
                visible={showDsFinVkPopup}
                hideOnOutsideClick={true}
                onHiding={() => setShowDsFinVkPopup(false)}
                height={400}
                width={400}
                title={t("dailyClosingOverview.dsfinvkExport", "DSFinVK-Export")}
                style={{ color: "black", left: "35vw", position: "fixed", top: 400 }}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px"
                }}>
                    <DateBox
                        type="datetime"
                        label={t("dailyClosingOverview.startDate", "Startdatum")}
                        displayFormat="dd.MM.yyyy HH:mm"
                        value={startDate}
                        width="100%"
                        onValueChanged={(e) => setStartDate(e.value)}
                    />
                    <DateBox
                        type="datetime"
                        label={t("dailyClosingOverview.endDate", "Enddatum")}
                        displayFormat="dd.MM.yyyy HH:mm"
                        value={endDate}
                        width="100%"
                        onValueChanged={(e) => setEndDate(e.value)}
                    />
                    <SelectBox
                        items={[
                            { value: 'tar', text: 'TAR' },
                            { value: 'zip', text: 'ZIP' }
                        ]}
                        value={exportFormat}
                        onValueChanged={(e) => setExportFormat(e.value)}
                        label={t("dailyClosingOverview.format", "Format")}
                        labelMode="floating"
                        valueExpr="value"
                        displayExpr="text"
                        width="100%"
                    />
                    <div style={{ flexDirection: "row", marginTop: "20px" }}>
                        <Button
                            text={t("common.cancel", "Abbrechen")}
                            onClick={() => setShowDsFinVkPopup(false)}
                            type="danger"
                            style={{ width: 100, height: 40 }}
                        />
                        <Button
                            text={t("common.export", "Exportieren")}
                            type="default"
                            onClick={handleExport}
                            disabled={!startDate || !endDate}
                            style={{ marginLeft: 20, width: 100, height: 40 }}
                        />
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default withTranslation(["dynamicTranslation"])(DsfinvkExports);
